import { Disclosure } from "@headlessui/react";
import { useEffect, useState } from "react";
import logo from "../../src/assets/spherity-s-1.png";
import "../index.css";
import { agent } from "../veramo/setup";

export const Home: React.FC = () => {
  const [didDoc, setDidDoc] = useState<any>();
  type service = {
    id: string;
    serviceEndpoint: string;
    type: string;
    description: string;
  };

  const resolve = async () => {
    const doc = await agent.resolveDid({
      didUrl: "did:web:testing-did-web.spherity.com",
    });
    setDidDoc(doc);
  };

  useEffect(() => {
    resolve();
  }, []);

  const chooseBgColor = (path: any) => {
    return "bg-cyan-900";
  };

  return (
    <>
      <Disclosure as="nav" className={chooseBgColor}>
        <div className="px-2 w-full">
          <div className="flex  justify-between h-20 ">
            <div className="flex items-center mt-2 mb-1 ml-20 ">
              <div className="h-full flex justify-between items-center w-fit ">
                <img
                  className="h-10 w-10 object-cover"
                  src={logo}
                  alt="Workflow"
                />
              </div>
              <div className="ml-2 invisible sm:visible  font-semibold text-xl text-white">
                Digital Product Passport
              </div>
              <div className="flex ml-2 visible sm:invisible  font-semibold text-xx text-white">
                DPP
              </div>
            </div>
            <div className="flex items-center mt-2 mb-1 ml-4 mr-12">
              <a
                href="https://www.spherity.com/digital-product-pass"
                className="font-medium text-bold text-white hover:underline invisible md:visible  "
              >
                More Informations
              </a>
            </div>
          </div>
        </div>
      </Disclosure>

      <div className="flex h-screen bg-indigo-50 h-full w-full  overflow-scroll   ">
        <div className="flex max-w-7xl px-4 sm:px-6 md:px-8  ">
          <div className="md:flex">
            <div className="max-w-7xl px-4 ">
              <div className="px-4 sm:px-6  max-w-7xl px-4 sm:px-5 shadow overflow-hidden text-center sm:text-left  mt-5 bg-gray-200">
                <div className=" flex inline-block justify-between ">
                  <h3 className="group text-xl leading-6 font-medium text-black mb-2 mt-2 text-cyan-900 font-bold cursor-pointer">
                    <p className=" group-hover:scale-x-110 text-gray   italic text-sm font-normal">
                      "{didDoc && didDoc.didResolutionMetadata.did.didString}"
                    </p>
                    <p> Universal Resolver Link</p>
                  </h3>

                  <div className="flex items-center mt-2 mb-1 ml-4 mr-1">
                    <h4 className="text-base cursor-pointer hover:scale-50 ">
                      JSON
                    </h4>
                  </div>
                </div>
              </div>
              <div className="flex px-2 py-4 mt-3 sm:px-3  justify-between shadow overflow-hidden sm:rounded-lg border-t border-gray-200 bg-white">
                <div className="inline-block overflow-y-scroll h-96 max-w-3xl bg-black">
                  <pre id="json" className="text-white">
                    {didDoc && JSON.stringify(didDoc, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
            <div className="max-w-7xl px-4 ">
              <div className="px-4 sm:px-6 md:px-8 max-w-8xl px-4 sm:px-5 md:px-5 shadow overflow-hidden sm:rounded-lg mt-5 bg-gray-200">
                <div className="text-xl leading-6 font-medium  mb-2 mt-2 text-cyan-900 font-bold cursor-pointer">
                  <h3>Services</h3>
                </div>
              </div>
              <div className="mt-2">
                {didDoc?.didDocument.service.map((s: service) => {
                  return (
                    <div className="flex max-w-9xl  bg-indigo-50 block ">
                      <div className="  overflow-hidden rounded-xl bg-white shadow-md duration-200 mb-5 hover:shadow-xl">
                        <div className="p-3">
                          <p className=" text-sm mb-5 text-black text-base ">
                            {s.description}
                          </p>
                          <div className="mb-3">
                            <a
                              className="px-6 py-3  text-blue-100 no-underline bg-cyan-700 rounded hover:bg-cyan-900 hover:underline hover:text-white "
                              href={s.serviceEndpoint}
                            >
                              {s.type}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bottom-0  bg-indigo-50">
        <div className="max-w-7xl mx-auto py-8 px-4 overflow-hidden sm:px-6 lg:px-8">
          <p className="mt-2 text-center text-sm text-gray-400">
            &copy; {new Date().getUTCFullYear()} Spherity GmbH
          </p>
        </div>
      </footer>
    </>
  );
};
