import { createAgent, IResolver } from "@veramo/core";

import { DIDResolverPlugin } from "@veramo/did-resolver";
import { getUniversalResolver } from "@veramo/did-resolver/build/universal-resolver";
import { Resolver } from "did-resolver";


const uniResolver = getUniversalResolver('https://sure.spherity.com/1.0/identifiers/')

export const agent = createAgent<IResolver>({
  plugins: [
    new DIDResolverPlugin({
      resolver: new Resolver({
        web: uniResolver,
      }),
    }),
  ],
});
