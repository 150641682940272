import React, { FC } from "react";
import { Route, Routes } from "react-router";
import { Home } from "../pages/Home";

export default async function handler(req, res) {
  res.status(200).json({ test: "123" });
}

export const Router: FC = () => (
  <Routes>
    <Route path="/" element={<Home />} />
  </Routes>
);
